import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxMarkedCircleOutline
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { Link as RouteLink } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/general/Layout";
import { getExperiences } from "../components/general/store";

const useStyles = makeStyles(() => ({
    tableRoot: {
        margin: "5%",
        width: "90%"
    },
    linkpointer: {
        cursor: "pointer"
    },
    header: {
        "font-weight": "600",
        "font-size": "1rem"
    }
}));

const Experiences = () => {
    const classes = useStyles();
    return (
        <Layout>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" className={classes.tableRoot}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.header}>
                                Experiences
                            </TableCell>
                            <TableCell className={classes.header} align="right">
                                Done
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getExperiences().map(experience => (
                            <TableRow key={experience.name}>
                                <TableCell>
                                    <RouteLink
                                        className={classes.linkpointer}
                                        to={experience.route}
                                    >
                                        {experience.name}
                                    </RouteLink>
                                </TableCell>
                                <TableCell align="right">
                                    <Icon
                                        path={
                                            experience.visited
                                                ? mdiCheckboxMarkedCircleOutline
                                                : mdiCheckboxBlankCircleOutline
                                        }
                                        size={1}
                                        color={
                                            experience.visited
                                                ? "green"
                                                : "grey"
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Layout>
    );
};

export default Experiences;
